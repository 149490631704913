@import "../../assets/scss/variables";

body {
  transition: background-color 0.35s;
}

.main-app {
  background-color: $body-bg !important;
}
.drawer-button {
  width: 2rem;
  height: 2rem;
}
.drawer-button:hover {
  cursor: pointer;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: $green;
  overflow-x: hidden;
  transition: 0.35s;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-right: 50px;
}

#main {
  transition: margin-right 0.35s;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.sidenav .menu {
  color: $white;
  min-width: 24.2rem;
  height: 100%;
  transition: all 0.35s ease-in-out;
  transform: translateX(0px);
}

.sidenav .menu a {
  font-size: 22px;
  color: $white;

  line-height: 28px;
  font-weight: 400;
  cursor: pointer;
}

.sidenav .down-carret {
  margin-bottom: 1rem;
  cursor: pointer;
}

.sidenav .menu .sub-menu a {
  font-size: 16px;
  line-height: 22px;
  margin-left: 2rem;
}
