// @import url('https://fonts.googleapis.com/css?family=Prompt:100,300,400,500,700,900&display=swap|Montserrat:100,300,400,500,700,900&display=swap|Mukta:200,300,400,500,600,700&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,600;1,300;1,600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// ################################ //
// ####                        #### //
// ####   COLORS DEFINITIONS   #### //
// ####                        #### //
// ################################ //

$white:      #FFFFFF;
$black:      #222;

$blue:       #69D8ED;
$green:      #09A68F; 
$red:        #F08181;
$orange:     #F1A153;
$pink:       #EE3C96;
$yellow:     #E6D757;
$teal:       #A7CF38;
$cyan:       #BE4597;

$indigo:     #AC27E1;
$purple:     #927FEC;

$gray:         #d3d3d3;
 
// Page Background
$body-bg:    #F5F5F5;

// Font Color
$body-color: #8B8B8B;  

// $gray-100: #f8f9fa;
$gray-100: #f3f3f3;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$nav-gray: #EDEDED;

// ################################ //
// ####                        #### //
// ####      BASE COLORS       #### //
// ####                        #### //
// ################################ //

$primary:       $blue;
$secondary:     $green;
$success:       $green;
$info:          $blue;
$warning:       $orange;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;
$sober-help:    #58ACE2;



// Main Wallpaper Background
$wallpaper-file: "clear.png";

// Sidebar Dock Background Color
$dockbar-bg: #343a40;

$header-overlay: #ffffff;
$title-color: $gray-500;

$footer-link:#7a8792;
$footer-link-hover:#ccc;

// ################################ //
// ####                        #### //
// ####      CLASS COLORS      #### //
// ####                        #### //
// ################################ // 

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "white":      $white,
    "muted":      $gray-100,
    "gray":       $gray-600,
    "gray-light": $gray-400,
    "blue":       $blue,
    "green":      $green,
    "red":        $red,
    "yellow":     $yellow,
    "purple":     $purple,
    "orange":     $orange
  ),
  $theme-colors 
);

// ################################ //
// ####                        #### //
// ####      BASE SETTINGS     #### //
// ####                        #### //
// ################################ //

$enable-caret:              true;
$enable-rounded:            true;
$enable-shadows:            true;
$enable-gradients:          false; 
$enable-transitions:        true;
$enable-grid-classes:       true;
$enable-print-styles:       true;

$paragraph-margin-bottom:     1rem;
$spacer:                      1.5rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4),
    7: ($spacer * 5),
    8: ($spacer * 6),
    9: ($spacer * 7),
    10: ($spacer * 8)
  ),
  $spacers
); 

$link-color:                  $primary;
$link-hover-color:            lighten($primary, 15%);

$border-color:                $gray-300;
$border-width:                0px; 
$border-radius:               10px;
$border-radius-lg:            25px;
$border-radius-sm:            5px;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
// $box-shadow:                  0px .8rem 5rem 1rem rgba(100, 100, 100, 0.05);
// $box-shadow:                  0 20px 50px 0 rgba(0,0,0,0.2);
$box-shadow:                  0 5px 22px rgba(#000, .1);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);
$box-shadow-xlg:               0 0.3rem 0.5rem rgba($black, .275);
$box-shadow-blue:             0 15px 30px rgba(44, 65, 225, 0.2);
$box-shadow-primary:             0 15px 30px rgba($primary, .075);
$box-shadow-secondary:             0 15px 30px rgba($secondary, .075);

// ################################ //
// ####                        #### //
// ####         FONTS          #### //
// ####                        #### //
// ################################ //


// $font-family-sans-serif:      'Montserrat', "Open Sans", "Segoe UI", "Helvetica Neue", Arial, sans-serif,-apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif:      'Poppins', 'Helvetica', 'Arial', sans-serif;
$font-family-base:            $font-family-sans-serif;

$line-height-lg:              1.5;
$line-height-sm:              1.5;

$font-size-base:              1rem;

$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);
$line-height-base:            1.5; 


$font-weight-1:               100;
$font-weight-2:               200;
$font-weight-3:               300;
$font-weight-4:               400;
$font-weight-5:               500;
$font-weight-6:               600;
$font-weight-7:               700;
$font-weight-8:               800;
$font-weight-9:               900;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-base:            $font-weight-normal;

$h1-font-size:                $font-size-base * 4.5;
$h2-font-size:                $font-size-base * 3;
$h3-font-size:                $font-size-base * 2.75;
$h4-font-size:                $font-size-base * 2;
$h5-font-size:                $font-size-base * 1.5;
$h6-font-size:                $font-size-base;
$lead-font-size:              $font-size-base * 1.25;

$headings-font-family:        'Prompt', "Open Sans", "Segoe UI", "Helvetica Neue", Arial, sans-serif,-apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight:        700;
$headings-line-height:        1.2;
$headings-color:              $gray-600;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;
$display1-weight:             800;
$display2-weight:             800;
$display3-weight:             800; 
$display4-weight:             800;
$display-line-height:         $headings-line-height;

$lead-font-weight:            700;

$grid-gutter-width:           30px;
$paragraph-margin-bottom:     1rem;

$component-active-color:      $white;
$component-active-bg:         $primary;
  
$caret-width:                 .3em;

$transition-base:             all .4s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;


// ################################ //
// ####                        #### //
// ####         BUTTONS        #### //
// ####                        #### //
// ################################ //

$btn-border-width:            1px;
$btn-border-radius:           10px;
$btn-border-radius-lg:        10px;
$btn-border-radius-sm:        10px;


// $btn-box-shadow:              none;
// $btn-box-shadow:              $box-shadow-lg;
$btn-box-shadow:              0 5px 10px rgba($primary, .5);
// $btn-focus-width:             $input-btn-focus-width !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       none;


$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


// ################################ //
// ####                        #### //
// ####       DROPDOWNS        #### //
// ####                        #### //
// ################################ //

$dropdown-min-width:                10rem;
$dropdown-padding-y:                .5rem;
$dropdown-spacer:                   .125rem;
$dropdown-bg:                       $white;
$dropdown-border-color:             rgba($black, .15);
$dropdown-border-radius:            $border-radius;
$dropdown-border-width:             0;
$dropdown-divider-bg:               $gray-200;
$dropdown-box-shadow:               $box-shadow;
$dropdown-link-color:               $gray-900;
$dropdown-link-hover-color:         darken($gray-900, 5%);
$dropdown-link-hover-bg:            $gray-100;
$dropdown-link-active-color:        $component-active-color;
$dropdown-link-active-bg:           $component-active-bg;
$dropdown-link-disabled-color:      $gray-600;
$dropdown-item-padding-y:           .25rem;
$dropdown-item-padding-x:           1.5rem;
$dropdown-header-color:             $gray-600;

// ################################ //
// ####                        #### //
// ####        NAVBARS         #### //
// ####                        #### //
// ################################ //

$navbar-padding-y:                  ($spacer / 2);
$navbar-padding-x:                  $spacer;
$navbar-nav-link-padding-x:         .5rem;
$navbar-brand-font-size:            $font-size-lg;

// ################################ //
// ####                        #### //
// ####          CARDS         #### //
// ####                        #### //
// ################################ //

$card-spacer-y:                     .75rem;
$card-spacer-x:                     1.25rem;
$card-border-width:                 0px;
$card-border-radius:                $border-radius;
$card-border-color:                 rgba($black, .125);
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:                       rgba(#FFF, .03);
$card-bg:                           $white;

$card-img-overlay-padding:          1.25rem;

$card-group-margin:                 ($grid-gutter-width / 2);
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;

// ################################ //
// ####                        #### //
// ####         MODALS         #### //
// ####                        #### //
// ################################ //

$modal-content-bg:                  $white;
$modal-content-border-color:        rgba($black, .2);
$modal-content-border-width:        0px;
$modal-content-border-radius:       $border-radius;
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5);
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5);

$modal-backdrop-bg:                 $dark;
$modal-backdrop-opacity:            .95;
$modal-header-border-color:         $gray-200;
$modal-footer-border-color:         $modal-header-border-color;
$modal-header-border-width:         $modal-content-border-width;
$modal-footer-border-width:         $modal-header-border-width;
$modal-header-padding:              1rem;

$modal-lg:                          800px;
$modal-md:                          500px;
$modal-sm:                          300px;
 
$modal-transition:                  transform .2s ease-out;

// ################################ //
// ####                        #### //
// ####          FORMS         #### //
// ####                        #### //
// ################################ //

$input-btn-border-width:      $btn-border-width;
$input-border-radius: $btn-border-radius;

// Form label animations
// .form-label-group { position: relative; margin-bottom: 1rem; }
// .form-label-group > input, .form-label-group > label { height: 3.125rem; padding: .75rem; }
// .form-label-group > label { position: absolute; top: 0; left: 0; display: block; width: 100%; margin-bottom: 0; line-height: 1.5; color: #495057; pointer-events: none; cursor: text; border: 1px solid transparent; border-radius: .25rem; transition: $transition-base }
// .form-label-group input::-webkit-input-placeholder {color: transparent;}
// .form-label-group input:-ms-input-placeholder {color: transparent;}
// .form-label-group input::-ms-input-placeholder {color: transparent;}
// .form-label-group input::-moz-placeholder {color: transparent;}
// .form-label-group input::placeholder {color: transparent;}
// .form-label-group input:not(:placeholder-shown) { padding-top: 1.25rem; padding-bottom: .25rem; }
// .form-label-group input:not(:placeholder-shown) ~ label { padding-top: .25rem; padding-bottom: .25rem; font-size: 12px; color: #777; }
// // Fallback for Edge
// @supports (-ms-ime-align: auto) {  .form-label-group > label { display: none; } .form-label-group input::-ms-input-placeholder { color: #777;} }
// // Fallback for IE
// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { .form-label-group > label { display: none; } .form-label-group input:-ms-input-placeholder { color: #777; } }


// ################################ //
// ####                        #### //
// ####        UTILITIES       #### //
// ####                        #### //
// ################################ //

@media screen and (max-width: 600px) {
    html, body {font-size: 12px;}
}