@import "../../assets/scss/variables";

.rotating-phone {
    position: fixed;
    z-index: 1040;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: $sober-help;

    .center-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: $font-weight-5;
        font-size: 1.1rem;
        line-height: 124.8%;
        text-align: start;
        letter-spacing: -0.01em;
        color: $white;
    }
    .close-btn {
        background-color: $gray-500;
        // padding: 0.35rem;
        border-radius: 2rem;
        max-width: 2.3rem;
        max-height: 2.3rem;
        text-align: center;
        border-color: $sober-help !important;

        &:active {
            color: #212529;
            background-color: $sober-help !important;
            border-color: $sober-help !important;
        }
    }

    .btn-number {
        font-family: Poppins;
        height: 3rem;
        width: 112px;
        font-style: normal;
        font-weight: $font-weight-5;
        font-size: 1rem;
        line-height: 118.8%;
        letter-spacing: -0.01em;
        text-decoration-line: underline;
        box-shadow: $box-shadow-xlg;
        text-decoration: dashed;
    }
    @media only screen and (min-width: 601px) {
        .btn-number {
            font-size: 0.85rem;
            height: 2.5rem;
            width: 132px;
        }
    }
    @media only screen and (min-width: 769px) {
        .btn-number {
            font-size: 0.85rem;
            height: 3rem;
        }
    }
}

@media only screen and (max-width: 768px) {
    .rotating-phone {
        bottom: 0px;
        left: 0px;
        top: unset;
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
    }
}

.popover .popover-rehab {
    font-style: normal;
    font-weight: $font-weight-5;
    font-size: 1.25rem;
    line-height: 118.8%;
    letter-spacing: -0.01em;
    color: $blue;
}

.popover {
    max-width: 579px;
}
.popover .arrow {
    display: none !important;
}

div.popover.show {
    background-color: #ffffffff !important;
}

.popover-header {
    background-color: $white !important;
    color: $black;

    font-style: normal;
    font-weight: $font-weight-5;
    font-size: 1.25rem;
    line-height: 124.8%;
}
.popover-body {
    background-color: $white !important;
    color: $body-color;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.45rem;
    letter-spacing: 0.02em;
    /* or 21px */
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 1.8rem;
}
