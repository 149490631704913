@import "../../assets/scss/variables";

$soberIcon-size: 3rem;

.main-header {
  background: linear-gradient(rgba(105, 216, 237, 0.7), rgba(105, 216, 237, 0.7)),
    url("../../assets/img/header-bg.png") no-repeat;
  background-size: cover;
  min-height: 42rem;
}
.main-header-home {
  min-height: 27rem !important;
}
.main-header h1 {
  font-size: 4rem;
  color: $white;
  font-style: normal;
  font-weight: $font-weight-5;
  line-height: 105%;
}
.main-header h5 {
  color: $white;
  font-style: normal;
  font-weight: $font-weight-8;
  font-size: 1.62rem;
  line-height: 32px;
  text-transform: uppercase;
}

.text-white {
  color: $white;
}
.guide header p {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.02em;
}

.main-header .sub-title {
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 0.02em;
  line-height: 150%;
}

.main-header .custom-dropdown {
  height: 3.5rem;
}

.main-header .search .btn-blue {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.featured-carousel {
  .card {
    box-shadow: $box-shadow;
    margin-bottom: 0.9rem;
    cursor: pointer;
    display: flex;
    min-height: 9rem;
    transition: 0.5s;
  }
  .card-body {
    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 113.8%;
      color: $black;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 0.95rem;
      line-height: 126.4%;
      color: $gray-700;
      margin-top: 0.5rem;
    }
    .ad {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: $blue;
    }
    .phone-num {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 19px;
      margin: 0 !important;
    }

    @media only screen and (max-width: 480px) {
      .phone-num {
        font-size: 1.2rem;
      }
    }
    .view-center {
      margin-top: 8px;
      margin-bottom: -30px;
      z-index: 99;
      background-color: #f1f1f1;

      border-width: 1px;
      border-radius: 10px;
      border-style: solid;
      border-color: transparent;
      padding-top: 0.06rem;
      padding-bottom: 0.06rem;
      padding-right: 0.5rem;


      p {
        color: $green;
      }
      div {
        img {
          height: 2rem;
        }
      }
    }
    .dialer {
      flex-grow: 100;
      margin-top: 8px;
      margin-bottom: -30px;
      z-index: 99;
      background-color: $blue;

      border-width: 1px;
      border-radius: 10px;
      border-style: solid;
      border-color: transparent;
      padding-top: 0.06rem;
      padding-bottom: 0.06rem;

      p {
        color: $white;
      }
      div {
        img {
          height: 1rem;
        }
      }
    }
  }
}

.featured-carousel .card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

// Guide Banner
.single-product {
  min-height: 250px;
  background-color: $blue;
  background-image: url("../../assets/img/top-banner.png");
  // padding-top: 3rem;
  // padding-bottom: 3rem;

  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.single-product .single-product-text {
  font-size: 2.75rem;
  color: $white;
  // font-family: $headings-font-family;
  line-height: 3rem;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.single-product .app-links {
  font-weight: $font-weight-6;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: $white;
  text-align: center;
}
.apps .button {
  font-size: 20px;
  font-weight: $font-weight-6;

  width: 11rem;
  height: 3.5rem;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 15px;
  opacity: 1;
  cursor: pointer;
}
.apps .button img {
  width: 1.5rem;
  height: 1.8rem;
}
.apps .ios-button {
  margin-right: 8px;
  background: $black;
  color: $white;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .single-product .single-product-text {
    font-size: 2rem;
    text-align: center !important;
  }
}

.sidenav .apps .ios-button {
  margin-right: 0px;
  margin-top: 1.5rem;
}
.apps .play-store-button {
  margin-left: 8px;
  background: $white;
  color: $green !important;
  text-decoration: none;
}
.sidenav .apps .play-store-button {
  margin-left: 0px;
  margin-top: 1.5rem;
}

.main-app .fixed-content {
  position: fixed;
  width: 100%;
  height: 100%;
}

.mulitple-products .title {
  // font-family: $font-family-base;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 54px;
  color: $black;
}

.mulitple-products .sub-title {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 128%;
  letter-spacing: 0.02em;
  color: $gray-700;
}

.mulitple-products .community-chat-bg {
  background-color: $green;
}
.mulitple-products .dating-bg {
  background-color: $purple;
}

.mulitple-products h5 {
  // line-height: 40px;
  // font-family: $font-family-base;
  letter-spacing: -0.02rem;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: $gray-700;
}

.mulitple-products .img-container {
  width: 14.06rem;
  height: 15.31rem;
  border-radius: $border-radius;
}

.mulitple-products .img-container img {
  width: 12.65rem;
  height: 21.5rem;
  position: relative;
  bottom: 6rem;
}

.mulitple-products .btn-see-more {
  width: 10.69rem;
  height: 3.5rem;

  border-radius: $border-radius;
  color: $white;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.1rem;
}

.footer-link {
  color: $footer-link;
  text-decoration: none;
}
.footer-link:hover {
  color: $footer-link-hover;
  text-decoration: none;
}

.popover {
  border-radius: 10px !important;
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: rgba(0, 0, 0, 0.288) !important;
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: rgba(0, 0, 0, 0.288) !important;
}
.popover-header {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: $blue;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.popover-body {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: $white;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.state-selector {
  border-width: 0px !important;
  background: $white;
  border-radius: $border-radius !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8b8b8b;
  margin-top: 10px;
}

// Guide Section Tabs
.main-app .nav-tabs {
  border-radius: $border-radius;
  margin: 1rem auto;
  padding: 0.2rem;
}
.main-app .nav-tabs a {
  text-decoration: none;
  color: $body-color;
  flex-grow: 1;
}
.main-app .nav-tabs .nav-item .nav-title {
  padding: 8px 1rem;
  text-align: center;
  color: $white;
  font-weight: bold;
  font-size: 1rem;
}
.main-app .nav-tabs .active.nav-item .nav-title {
  background: $white;
  color: $black;
  border-radius: $border-radius;
  font-weight: bold;
  box-shadow: $box-shadow;
  font-size: 1rem;
}
.main-app .nav-tabs .active.nav-item a {
  text-decoration: none;
}

.main-app .tabs-section {
  background-color: $blue;
}

// Guide Listing
.guide .listing.card {
  box-shadow: $box-shadow;
  margin-bottom: 0.9rem;
  cursor: pointer;
  display: flex;
  min-height: 9rem;
  transition: 0.5s;
}
.guide .listing.card:hover {
  box-shadow: $box-shadow;
}
.guide .listing.card h5 {
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 500;
  line-height: 113.8%;
  color: $black;
}

.guide .soberIcon {
  background: $white;
  border-radius: $border-radius;
  border-color: $primary;
  border-width: 0.1rem;
  border-style: solid;
  // width: 5rem;
  // height: 5rem;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
}
// .guide .soberIcon img { filter: invert(1) brightness(300%); }
.guide .soberIcon img {
  // width: 3rem; height: 3rem;
  // width: 78.77px;
  // height: 89px;
}
.guide .listingServices {
  font-size: 0.8rem;
}
.guide .listingAddress {
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 126.4%;
  color: $gray-700;
}
.guide .listingExpand {
  font-size: 2rem;
  color: $gray-200;
}
.guide .listingServicesCol {
  margin-top: 3rem;
}
.guide .listingServicesTitle {
  font-weight: 600;
  color: $gray-600;
  margin-bottom: 0.6rem;
}

.listingServices {
  font-size: 0.8rem;
}
.listingAddress {
  color: $gray;
  font-size: 0.7rem;
}
.listingExpand {
  font-size: 2rem;
  color: $gray-200;
}
.listingServicesCol {
  margin-top: 3rem;
}
.listingServicesTitle {
  font-weight: 600;
  color: $gray-600;
  margin-bottom: 0.6rem;
}

.ad {
  color: $gray-600;
  font-size: 0.6rem;
  font-weight: 600;
}

.img-responsive {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 23.5rem;
}

.carousel-btn {
  width: 42px;
  height: 42px;
  border-radius: 21px;
}

.react-multiple-carousel__arrow {
  background: $blue;
}

.listing-modal div section .logo-image {
  width: 108px;
  height: 108px;
  border-radius: 15px;
  object-fit: cover;
}
.listing-modal .title-info h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 54px;
  color: $black;
}
.listing-modal .title-info p {
  font-weight: 300;
  font-size: 1.15rem;
  line-height: 137%;
  letter-spacing: 0.02em;
  color: $gray-600;
}

.listing-modal .link-btn {
  width: 10.65rem;
  height: 3.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.13rem;
  line-height: 113.8%;
  display: flex;
  align-items: center;
  color: #ffffff;
  border-radius: $border-radius;
}

.listingProfile .fixed-bottom .link-btn {
  min-height: 4.2rem;

  border-radius: 0px;
  font-size: 1.4rem;

  position: fixed;
  bottom: 0;
  width: 100%;

  z-index: 9110;
}

.listing-modal .listingDescription .overview {
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: $black;
}

.listing-modal .listingDescription p {
  color: $black;
}

.slider-img {
  object-fit: cover;
  overflow: hidden;
  // max-width: 559px;
  max-height: 325px;
  margin-top: 0px !important;
}
.slider-img-radius {
  border-radius: $border-radius;
}
@media only screen and (max-width: 575px) {
  .slider-img {
    max-width: 100%;
  }
}

.card-logo-img {
  object-fit: cover;
  overflow: hidden;
  border-radius: $border-radius;
  height: 78px;
  width: 78px;
}

.listingProfile .main-listing .slider-img {
  width: 22rem;
  height: 13.5rem;
}
.listingProfile .main-listing .google-map {
  width: 22rem;
  height: 14rem;
  border-radius: $border-radius;
}

.listing-info .mobile .google-map {
  width: 100%;
  height: 14rem !important;
}
.google-map {
  width: 22rem !important ;
  height: 14rem;
  border-radius: 10px;
}
@media only screen and (max-width: 575px) {
  .google-map {
    width: 100% !important ;
    border-radius: 0px !important ;
  }
}

.carousel-indicators {
  bottom: 1.8rem !important;
}

.carousel-indicators li {
  width: 8px;
  height: 8px;
  border-top: none !important;
  border-bottom: none !important;
  border-radius: 4px;
}

.sub-info .info {
  font-style: normal;
  font-weight: 400;
  font-size: 1.05rem;
  line-height: 137%;
  letter-spacing: 0.02em;
  color: $black;
  cursor: pointer;
}

// zip code link in details page
.sub-info .info a {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 1.05rem;
  line-height: 137%;
  letter-spacing: 0.02em;
  color: $black;
  cursor: pointer;
}

// Listing Profile
.guide .guideListing h5 {
  font-size: 1rem;
}
.guide .listingProfileWindow {
  max-height: 100vh;
  width: 70vw;
  position: fixed;
  top: 0;
  right: -71vw;
  overflow-y: scroll;
  transition: $transition-base;
}
.guide .listingProfile {
  padding: 2rem 3rem 2rem 4rem;
  border-radius: $border-radius-lg 0 0 $border-radius-lg;
  cursor: default;
  transition: $transition-base;
}
.guide .listingProfileClose {
  display: none;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: end;
  top: 0;
  right: -10vw;
  height: 100vh;
  width: 3rem;
  font-size: 2rem;
  cursor: pointer;
  color: $gray;
  z-index: 5;
  transition: $transition-base;
}
.guide .listingProfileWindow.open {
  right: 0;
}
.guide .open .listingProfileClose {
  right: calc(70vw - 3rem);
  width: 100vw;
}

// Pagination
.page-item .page-link {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 10px;
  box-shadow: $box-shadow;
  font-size: 1.15rem;
  font-family: $font-family-base;
  font-weight: $font-weight-5;
  line-height: 30px;
}

.page-item:last-child .page-link {
  margin-right: 0px;
}

.listing-detail {
  background: linear-gradient(rgba(105, 216, 237, 0.65), rgba(105, 216, 237, 0.65)),
    url("../../assets/img/listing-detail-head.png") no-repeat;
  background-size: cover;
  min-height: 39rem;
}

.listing-detail .nav-main {
  padding: 1rem;
}

.listing-detail div h1 {
  font-size: 3.8rem;
  font-weight: $font-weight-6;
  color: $white;
}
.listing-detail .main-info p {
  font-size: 1.4rem;
  color: $white;
  font-weight: 400;
}

.listing-detail .action-btn button {
  border-width: 0px;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 0.8rem 1.4rem 0.8rem 1.4rem;
  font-weight: 500;
  font-size: 1.4rem;
}
.listing-detail .web-btn {
  color: $gray-600;
  background-color: $white;
  margin-right: 0.7rem;
}
.listing-detail .web-btn img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}
.listing-detail .phone-btn {
  // font-size: 3.5rem;

  color: $white;
  background-color: $blue;
  margin-left: 0.3rem;
}
.listing-detail .phone-btn img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
}

.back-btn {
  background: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 1.6rem 1.3rem 1.6rem 1.3rem;
  cursor: pointer;
  text-decoration: none;
}

.counseling-service .listing-title {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 29px;
  color: $black;
  font-family: $font-family-sans-serif;
}

.listingProfile .listingServices .list-item {
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 128%;
  letter-spacing: 0.02em;
  color: $black;
}

.list-item .sub-txt {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 128%;
  letter-spacing: 0.02em;
  color: #818181;
  // color:$gray-700
}

.listingProfile .main-listing .over-view p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: $black;
}

.bread-crumb a {
  font-style: normal;
  font-weight: 300;
  font-size: 1.15rem;
  line-height: 137%;
  letter-spacing: 0.02em;
  color: $gray-700;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
}

.bread-crumb h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.95rem;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: $black;
  text-transform: capitalize;
}

.home-section .sober-icon {
  width: 11.25rem;
  height: 11.25rem;
}

.home-section h1 {
  font-style: normal;
  font-weight: $font-weight-6;
  font-size: 3.5rem;
  line-height: 105%;
  letter-spacing: 0.02rem;
  color: $black;
  font-family: $font-family-base;
}

.home-section h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.45rem;
  line-height: 29px;
  letter-spacing: 0.03rem;
  color: $gray-700;
}
.home-section .main-text {
  font-style: normal;
  font-weight: $font-weight-6;
  font-size: 1.75rem;
  // line-height: 130%;
  /* or 36px */

  letter-spacing: -0.02em;
  color: $gray-700;
}

.home-section .sub-text {
  font-style: normal;
  font-weight: $font-weight-5;
  font-size: 1.25rem;
  // line-height: 124.8%;
  // letter-spacing: -0.01em;
  color: $gray-700;
}

.home-section .rehab-title {
  color: $blue;
  font-style: normal;
  font-weight: $font-weight-6;
  font-size: 1.75rem;
  line-height: 130%;
  letter-spacing: -0.02em;
  margin-top: 1rem;
}

.home-section .rehab-info {
  color: $gray-700;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 137%;
  letter-spacing: 0.02em;
}
.home-section .rehab-useful-src {
  color: $gray-700;
  font-size: 1.75rem;
  line-height: 137%;
  letter-spacing: 0.02em;

  font-style: normal;
  font-weight: $font-weight-6;
  margin-top: 1rem;
}
.home-section .rehab-faq {
  color: $green;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 137%;
  /* or 23px */
}

@media only screen and (max-width: 768px) {
  .home-section h1 {
    font-size: 2rem;
    margin-top: 1rem;
  }
  .home-section h4 {
    font-size: 1rem;
  }
}

.dropdown-input {
  z-index: 223;
  .city_state-input {
    border-color: transparent;
    box-shadow: $box-shadow;
    font-style: normal;
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 128%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: $gray-500;
    height: 3.5rem;
    width: 25.8rem;
    border-radius: $border-radius;
    padding-left: 1.3rem;
  }
}

@media only screen and (max-width: 480px) {
  .dropdown-input .city_state-input {
    width: 17rem;
  }
}

.drop-down-section .dropdown-input .city_state-input {
  width: 12rem;
}

.dropdown-input .dropdown-suggestions {
  background: $white;
  position: absolute;
  border-radius: $border-radius;
  max-width: 25.8rem;
  z-index: 900;
}
.dropdown-input .dropdown-suggestions .dropdown-suggestion-text {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8b8b8b;
  text-align: left;
}
.hover {
  cursor: pointer;
}
