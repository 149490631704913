.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(90%, 0);
  transform: translate(90%, 0);

  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
  width: 90%;
  margin-top: 0px;
  position: fixed;
  right: 0;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;

  //   max-height: 100vh; width: 70vw; position: fixed; top: 0; right: 71vw; overflow-y: scroll;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);

  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
  min-width: 70vw;

  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .modal.fade.show .modal-dialog.modal-dialog-slideout {
    min-width: 100vw;
    border-radius: 0px;
  }
}
.slider {
}
.slider-radius {
}
@media only screen and (max-width: 480px) {
  .slider {
    margin-left: -3.5rem;
    margin-right: -3rem;
  }
  .slider-radius {
    border-radius: 0px;
  }
}

.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 95%;
  width: 100%;
  padding: 0;
}

.modal-dialog-slideout .modal-content {
  border: 6px;
  border-radius: 6px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 69px;
  display: block;
}

.modal-dialog-slideout .modal-header h5 {
  float: left;
}

.modal-backdrop {
  background: rgba(129, 129, 129, 0.7);
}

.curved-close {
  top: 34px;
  left: -32px;
  width: 51px;
  height: 160px;
  opacity: 1;
  position: fixed;
  background-image: url("../../assets/img/close-modal.svg");
  z-index: 999;
}
.curved-close:hover {
  cursor: pointer;
}

.modal-close-btn {
  width: 47px;
  height: 47px;
  opacity: 1;
  background-image: url("../../assets/img/close-btn.svg");
  position: fixed;
  top: 10px;
  left: -8px;
  z-index: 999;
}
.modal-close-btn:hover {
  cursor: pointer;
}
