// Bootstrap default variables
@import "./assets/scss/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";

html {
  height: 100%;
}
body {
  min-height: 100%;
}
.fixed-top {
  position: fixed;
  top: 4.5rem;

  .logo {
    width: 2.7rem;
    height: 2.7rem;
  }
}
.fixed-top-override {
  position: fixed;
  top: 0rem !important;
}

@media only screen and (max-width: 768px) {
  .fixed-top {
    position: fixed;
    top: 0rem;
  }
}

header .logo {
  //   width: 25rem;
}
header .logoTitle {
  font-size: 2.69rem;
  color: $white;
  margin-left: 0.7rem !important;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  letter-spacing: 1.25px;
}
header .logo-subTitle {
  font-size: 2.69rem;
  font-weight: 300;
  color: $white;
  letter-spacing: 0.5px;
  font-family: $font-family-sans-serif;
  margin-left: 0.4rem !important;
}
header {
  padding: 1rem;
}
@media only screen and (max-width: 480px) {
  header .logoTitle {
    font-size: 1.69rem;
  }
  header .logo-subTitle {
    font-size: 1.69rem;
  }
}
.main {
  flex: 1;
  min-height: 70vh;
}
footer {
  display: flex;
  align-self: flex-end;
}
.btn-primary {
  color: $white;
}

.loosid-home-btn {
  margin-right: 3rem !important;
  background-color: $white !important;
  color: $green !important;
  border-color: $white !important;
  font-weight: 500;
}
